import {
  deserialiseDate,
  serialisedDateSchema,
  type DeserialiseDates,
  type SerialisedDate,
} from "~/src/models/utils/Serialise.model";
import { z } from "zod";

import type {
  APIPagedResponse,
  ListRequestModel,
} from "~/src/models/utils/Api.model";
import type { PortViewModel } from "~/src/models/Port/Port.viewModel";

export type IncidentViewModel = {
  id: string;
  portOfLoading: PortViewModel;
  portOfDischarge: PortViewModel;
  transshipmentPorts: PortViewModel[];
  containerType: string;
  methodology: string;
  containerNumber: string;
  sailDate: SerialisedDate;
  incidentDate: SerialisedDate;
  inspectionLocation?: string;
};
export type FrontendIncidentViewModel = DeserialiseDates<IncidentViewModel>;

export function deserialiseIncident(
  obj: IncidentViewModel
): FrontendIncidentViewModel {
  (obj.sailDate as any) = deserialiseDate(obj.sailDate);
  (obj.incidentDate as any) = deserialiseDate(obj.incidentDate);
  return obj as any as FrontendIncidentViewModel;
}
export function deserialisePagedIncident(
  res: APIPagedResponse<IncidentViewModel>
) {
  for (let i = 0; i < res.data.length; ++i) {
    (res.data[i] as any) = deserialiseIncident(res.data[i]);
  }
  return res as any as APIPagedResponse<FrontendIncidentViewModel>;
}

export const incidentCreateSchema = z.object({
  portOfLoading: z.string(),
  portOfDischarge: z.string(),
  transshipmentPorts: z.array(z.string()),
  containerType: z.string(),
  methodology: z.string(),
  containerNumber: z.string(),
  sailDate: serialisedDateSchema,
  incidentDate: serialisedDateSchema,
  inspectionLocation: z.string().optional(),
});
export type IncidentCreateModel = z.infer<typeof incidentCreateSchema>;
export type FrontendIncidentCreateModel = DeserialiseDates<IncidentCreateModel>;

export const incidentUpdateSchema = incidentCreateSchema;
export type IncidentUpdateModel = IncidentCreateModel;
export type FrontendIncidentUpdateModel = FrontendIncidentCreateModel;

export type IncidentListQueryRequest = ListRequestModel;
